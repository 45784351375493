import React from 'react';

import Create from '../Create';

const Home = () => {
    return (
        <div>
            <Create />
        </div>
    );
};

Home.propTypes = {};

export default Home;
